import styled from "styled-components";

const MusicPlayerBarContainer = styled.div`
  width: 100%;
  height: 55px;
  position: fixed;
  bottom: 0;
  padding: 0em 1em;
  margin-bottom: 0.5em;
  z-index: 1000;
`;

const MusicPlayerBarDetails = styled.div`
  border-radius: 5px;
  background-color: #1d1d1d;
  height: 55px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding-right: 1em;
  position: relative;
  z-index: 100000;
`;

const SongWithSingerName = styled.div`
  width: 80%;
`;

const SongName = styled.p`
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
`;
const SingerName = styled.p`
  color: #75808b !important;
  font-size: 10px;
`;
const MusicPlayerBarControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;
const PauePlayButtonIcon = styled.div``;

const ImageWithDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  margin-left: 12px;
  width: 65%;
  img {
    width: 42px;
    height: 42px;
    border-radius: 5px;
  }
`;

const MusicPlayerBarTrack = styled.div`
  position: absolute;
  bottom: -3px;
  width: 98%;
`;
export {
  ImageWithDetails,
  MusicPlayerBarContainer,
  MusicPlayerBarControls,
  MusicPlayerBarDetails,
  MusicPlayerBarTrack,
  PauePlayButtonIcon,
  SingerName,
  SongName,
  SongWithSingerName,
};
