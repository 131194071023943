import axios from "axios";

export const updatePlayList = async (
  // data,
  playlist_id,
  track_id,
  authToken
) => {
  try {
    const params = {
      method: "PUT",
      baseURL: process.env.REACT_APP_BASE_URL,
      url: `/api/playlist/${playlist_id}/track/${track_id}`,
      headers: {
        authToken,
      },
      // data,
    };

    const response = await axios(params);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const createPlaylist = async (authToken, data) => {
  try {
    const params = {
      method: "POST",
      baseURL: process.env.REACT_APP_BASE_URL,
      url: `/api/playlist/`,
      headers: {
        authToken,
      },
      data,
    };
    const response = await axios(params);

    console.log("....?????", response);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getUserPlayList = async (authtoken, mynID) => {
  console.log("getUserplalsit", authtoken, mynID);
  try {
    const params = {
      method: "GET",
      baseURL: process.env.REACT_APP_BASE_URL,
      url: `/api/user/${mynID}/playlists`,
      headers: {
        authtoken,
      },
    };

    const response = await axios(params);

    console.log("responsee", response);
    return await response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getPlayListByPlayListID = async (authtoken, playListID) => {
  try {
    const params = {
      method: "GET",
      baseURL: process.env.REACT_APP_BASE_URL,
      url: `api/playlist/${playListID}`,
      headers: {
        authtoken,
      },
    };

    const response = await axios(params);

    console.log("response", response);
    return await response.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteTrackFromPlayList = async (
  authtoken,
  playlist_id,
  track_id
) => {
  try {
    const params = {
      method: "DELETE",
      baseURL: process.env.REACT_APP_BASE_URL,
      url: `api/playlist/${playlist_id}/track/${track_id}`,
      headers: {
        authtoken,
      },
    };

    const response = await axios(params);

    return await response.data;
  } catch (err) {
    console.log(err);
  }
};

// gener
export const retrievelanguageList = async () => {
  try {
    const params = {
      method: "GET",
      baseURL: process.env.REACT_APP_BASE_URL,
      url: `/api/language/list`,
    };

    const response = await axios(params);
    console.log("language-list", response);

    return await response.data;
  } catch (err) {
    console.log(err);
  }
};

export const retrieveGenersList = async () => {
  try {
    const params = {
      method: "GET",
      baseURL: process.env.REACT_APP_BASE_URL,
      url: `api/genres/list`,
    };

    const response = await axios(params);
    console.log("genre-list", response);

    return await response.data;
  } catch (err) {
    console.log(err);
  }
};

export const deletePlayList = async (authtoken, playlist_id) => {
  try {
    const params = {
      method: "DELETE",
      baseURL: process.env.REACT_APP_BASE_URL,
      url: `api/playlist/${playlist_id}`,
      headers: {
        authtoken,
      },
    };

    const response = await axios(params);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
