import React, { createContext } from "react";
import { toast } from "react-toastify";

const NotifyContext = createContext();

const NotifyProvider = ({ children }) => {
  const notify = (message) => {
    toast(message);
  };

  return (
    <NotifyContext.Provider value={{ notify }}>
      {children}
    </NotifyContext.Provider>
  );
};

export { NotifyContext, NotifyProvider };
