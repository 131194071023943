import React from "react";
import { useSelector } from "react-redux";
import {
  DurationTrackContainer,
  SeekBar,
  TimingTrack,
} from "./DurationTrack.styled";

function DurationTrack({
  value,
  min,
  max,
  onInput,
  setSeekTime,
  appTime,
  onBar,
  barHeight,
}) {
  // converts the time to format 0:00
  console.log("value", value);
  console.log("max", max);
  const { themeMode } = useSelector((state) => state.player);

  const getTime = (time) =>
    `${Math.floor(time / 60)}:${`0${Math.floor(time % 60)}`.slice(-2)}`;

  return (
    <DurationTrackContainer theme={themeMode}>
      <SeekBar theme={themeMode} barHeight={barHeight}>
        <input
          type="range"
          step="any"
          value={value}
          min={0}
          max={max}
          onInput={(event) => onInput(event)}
          style={{ height: barHeight }}
        />
      </SeekBar>
      {!onBar ? (
        <TimingTrack>
          <p className="text-white">{value === 0 ? "0:00" : getTime(value)}</p>

          <p className="text-white">{max === 0 ? "0:00" : getTime(max)}</p>
        </TimingTrack>
      ) : null}
    </DurationTrackContainer>
  );
}

export default DurationTrack;
