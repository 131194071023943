import React, { useState, useEffect } from 'react';
import { Container, Content } from './AnimatedTexts';

const AnimatedText = ({ trackTitle }) => {
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    const handleAnimationIteration = () => {
      setPaused(true);
      setTimeout(() => {
        setPaused(false);
      }, 2000);
    };

    const marqueeContent = document.querySelector('.marquee-content');
    marqueeContent.addEventListener('animationiteration', handleAnimationIteration);

    return () => {
      marqueeContent.removeEventListener('animationiteration', handleAnimationIteration);
    };
  }, []);

  return (
    <Container>
      <Content className="marquee-content" paused={paused}>
        {trackTitle}
      </Content>
    </Container>
  );
};

export default AnimatedText;
