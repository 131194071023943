import styled from "styled-components";

const MusicPlayerBannerContainer = styled.div`
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    border-radius: 1em;
  }
`;

export { MusicPlayerBannerContainer };
