import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsShuffle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavTracks,
  toggleAlbumShuffleStatus,
} from "../../../redux/features/playerSlice";
import AnimatedText from "./AnimatedText/AnimatedText";
import {
  PlayerDetails,
  PlayerSingerName,
  PlayerTitleWithPowerPlayContainer,
  PlayerTrackTitle,
  PowerPlay,
} from "./PlayerTitleWithPowerPlay.styled";

function PlayerTitleWithPowerPlay({ track_id, notify }) {
  const { activeSong, favTracks } = useSelector((state) => state.player);

  const { authToken, mynId, currentAlbumShuffleStatus } = useSelector(
    (state) => state.player
  );
  const { themeMode } = useSelector((state) => state.player);

  const [isFav, setIsFav] = useState(false);
  const dispatch = useDispatch();
  const loopCodeMap = new Map([
    [0, "white"],
    [1, "red"],
    [2, "orange"],
  ]);

  let activeSongTitle = activeSong.title || activeSong.track_name;
  let activeSongArtistName =
    activeSong.artist ||
    activeSong.track_artist ||
    activeSong.artists.singers[0];

  /* function handleShuffle() {
    localStorage.setItem("")
  } */

  /* useState(() => {
    handleLoop();
  }, []); */

  /* function handleLoop() {
    if (loopType === 2) {
      dispatch(incrementLoopType(0));
      setColor(loopCodeMap.get(loopType));
      return;
    }
    dispatch(incrementLoopType());
    setColor(loopCodeMap.get(loopType));
    console.log("looptype-1", loopType);

   
  } */

  //console.log("player-rendered");

  useEffect(() => {
    setIsFav(false);

    favTracks.forEach((value) => {
      if (value.track_id === track_id) {
        setIsFav(true);
        return;
      }
    });
  }, [track_id, mynId, authToken, favTracks]);

  function handleShuffle() {
    console.log("clicked");
    console.log("currentAlbumShuffleStatus", currentAlbumShuffleStatus);
    //dispatch(toggleAlbumShuffleStatus(!currentAlbumShuffleStatus));
    if (!currentAlbumShuffleStatus) {
      dispatch(toggleAlbumShuffleStatus(true));
      return;
    }
    dispatch(toggleAlbumShuffleStatus(false));
  }

  function handleOnTrackAddToFav() {
    let isPresent = false;
    let trackIdToBeDeleted;
    console.log("add");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/user/${mynId}/favourites`, {
        headers: {
          authtoken: authToken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("response-track----->>", res.data);
        const allFavTracks = res.data.result;
        console.log("allfavtracsk", allFavTracks);
        allFavTracks.forEach((value) => {
          console.log(value.track_id, track_id);
          console.log("equality-check", value.track_id === track_id);
          if (value.track_id === track_id) {
            console.log("present");
            isPresent = true;
            trackIdToBeDeleted = value.track_id;
            return;
          }
        });
        if (isPresent) {
          console.log("trackIdtobedeleted", trackIdToBeDeleted);
          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/api/user/${mynId}/favourites`,
              {
                track_id: [trackIdToBeDeleted],
              },
              {
                headers: {
                  authtoken: authToken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              console.log("deleted", res.data.result);
              notify("Done. The song is off your MYtunes.");
              dispatch(getFavTracks());
            });
        } else {
          let data = JSON.stringify({
            track_id: [`${track_id}`],
          });

          console.log("data", data);
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_URL}/api/user/${mynId}/favourites`,
            headers: {
              authtoken: authToken,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              console.log("posted");
              console.log("response", response.data);
              notify("Great taste! Music added to MYtunes.");

              dispatch(getFavTracks());
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
  }
  return (
    <PlayerTitleWithPowerPlayContainer theme={themeMode}>
      <PlayerDetails>
        <PlayerTrackTitle>
          {/* {activeSong.title?.slice(0, 10) || activeSong.track_name.slice(0, 10)}
          ... */}
          {activeSongTitle.length > 12 ? (
            <AnimatedText trackTitle={activeSongTitle} />
          ) : (
            activeSongTitle
          )}
        </PlayerTrackTitle>

        <PlayerSingerName>
          {/* {activeSong.artist ||
            activeSong.track_artist ||
            activeSong.artists.singers[0]} */}
          {activeSongArtistName.length > 20 ? (
            <AnimatedText trackTitle={activeSongArtistName} />
          ) : (
            activeSongArtistName
          )}
        </PlayerSingerName>
      </PlayerDetails>
      <PowerPlay>
        <div id="suffle_div">
          <BsShuffle
            size={22}
            onClick={() => handleShuffle()}
            style={{
              color:
                currentAlbumShuffleStatus === true
                  ? "red"
                  : themeMode === "light" && "#000",
            }}
          />
        </div>
        <div onClick={() => handleOnTrackAddToFav()}>
          {isFav ? (
            <AiFillStar size={26} style={{ color: "red" }} />
          ) : (
            <AiOutlineStar size={26} />
          )}
        </div>

        {/* <SlLoop size={22} onClick={handleLoop} style={{ color: `${color}` }} /> */}
      </PowerPlay>
    </PlayerTitleWithPowerPlayContainer>
  );
}

export default PlayerTitleWithPowerPlay;
