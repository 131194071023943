import React, { createContext, useState } from "react";

const PlayerContext = createContext();

const PlayerProvider = ({ children }) => {
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  const openPlayer = () => {
    setIsPlayerOpen(true);
  };

  const closePlayer = () => {
    setIsPlayerOpen(false);
  };

  return (
    <PlayerContext.Provider value={{ isPlayerOpen, openPlayer, closePlayer }}>
      {children}
    </PlayerContext.Provider>
  );
};

export { PlayerContext, PlayerProvider };
