import styled from "styled-components";

const DurationTrackContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme === "dark" && "#fff"};
  gap: 0.4em;
`;

const TimingTrack = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-weight: 500;
    font-size: 13px;
  }
`;

const SeekBar = styled.div`
  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    border-radius: 15px;
    background: #aaa;
    outline: none;
    overflow: hidden;
    margin: 0.2em;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 40px;
    // background: #f5f5f5;
    background: ${({ theme, barHeight }) => (theme === 'light' && barHeight === '5px') ? '#000' : '#f5f5f5'};
    border-radius: 10px;
    // box-shadow: -350px 0px 0px 340px #f5f5f5;
    box-shadow: -350px 0px 0px 340px ${({ theme, barHeight }) => (theme === 'light' && barHeight === '5px') ? '#000' : '#f5f5f5'};
    cursor: pointer;
  }
`;

export { DurationTrackContainer, SeekBar, TimingTrack };
