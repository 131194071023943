import { useRoutes } from "react-router-dom";

import { lazy } from "react";

const PlayLists = lazy(() => import("../pages/PlayLists/PlayLists"));
const MytunesPage = lazy(() => import("../pages/MytunesPage/MytunesPage"));
const LatestAdded = lazy(() => import("../pages/Dowloads/Downloads"));
const AlbumView = lazy(() => import("../pages/AlbumView/AlbumView"));
const SongCredits = lazy(() => import("../pages/SongCredits/SongCredits"));
const PlayListSongs = lazy(() =>
  import("../pages/PlayListSongs/PlayListSongs")
);
const AudioHomePage = lazy(() =>
  import("../pages/AudioHomePage/AudioHomePage")
);
const GradientsPage = lazy(() =>
  import("../pages/GradientsPage/GradientsPage")
);
const RecentlyPlayed = lazy(() =>
  import("../pages/RecentlyPlayed/RecentlyPlayed")
);
export const AllRoutes = () => {
  const elements = useRoutes([
    {
      path: "/",
      element: <AudioHomePage />,
    },
    {
      path: "/gradients",
      element: <GradientsPage />,
    },
    {
      path: "/recently-played",
      element: <RecentlyPlayed />,
    },
    {
      path: "/playlist",
      element: <PlayLists />,
    },
    {
      path: "playlist-songs",
      element: <PlayListSongs />,
    },
    {
      path: "/mytunes",
      element: <MytunesPage />,
    },
    {
      path: "/downloads",
      element: <LatestAdded />,
    },
    {
      path: "/album-view",
      element: <AlbumView />,
    },
    {
      path: "/song-credits",
      element: <SongCredits />,
    },
  ]);

  return elements;
};
