import React from 'react'
import Lottie from "react-lottie";

import MYtunes_loading from "../../assets/MYtunes_loading.json"


const MusicLottieLoader = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: MYtunes_loading,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      

  return (
    <div id="musicLottieLoader">
    <Lottie options={defaultOptions} height={200} width={200} />
  </div>
  )
}

export default MusicLottieLoader