import React, { useState } from "react";
import albumImage from "../../../assets/album.png";
import { MusicPlayerBannerContainer } from "./MusicPlayerBanner.styled";
function MusicPlayerBanner({ musicBanner }) {
  //const image= useSelector(state=>state)
  const [imageLoadError, setImageLoadError] = useState(false);
  const handleImageError = () => {
    setImageLoadError(true);
  };
  return (
    <MusicPlayerBannerContainer>
      <img
        src={imageLoadError ? albumImage : musicBanner}
        alt="img"
        onError={handleImageError}
      />
    </MusicPlayerBannerContainer>
  );
}

export default MusicPlayerBanner;
