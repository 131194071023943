import styled, { keyframes, css } from 'styled-components';

const Container = styled.div`
  overflow: hidden;
  position: relative;
`;

const marquee = keyframes`
//   0% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
  50% {
    transform: translateX(-40%);
  }
`;

const Content = styled.div`
  display: inline-block;
  white-space: nowrap;
  animation: ${({ paused }) =>
    paused
      ? css`
          none
        `
      : css`
          ${marquee} 5s linear infinite
        `};
`;

export {Container, Content};