/* const [duration, setDuration] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  const [appTime, setAppTime] = useState(0); */

import React, { createContext, useState } from "react";

const TrackContext = createContext();

const TrackProvider = ({ children }) => {
  const [duration, setDuration] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  const [appTime, setAppTime] = useState(0);

  //const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  function setTrackDuration(duration) {
    console.log("duration", duration);
    setDuration(duration);
  }

  function setTrackAppTime(appTime) {
    console.log("apptime", appTime);
    setAppTime(appTime);
  }

  function setTrackSeekTime(seekTime) {
    console.log("trackseek time", seekTime);
    setSeekTime(seekTime);
  }
  return (
    <TrackContext.Provider
      value={{
        duration,
        appTime,
        seekTime,
        setTrackDuration,
        setTrackAppTime,
        setTrackSeekTime,
      }}
    >
      {children}
    </TrackContext.Provider>
  );
};

export { TrackContext, TrackProvider };
