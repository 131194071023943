import styled from "styled-components";

const AddSongWithShareAndControls = styled.div`
  display: flex;
  color:${({ theme }) => theme === 'dark' && '#ffff'};
  align-items: center;
  gap: 3em;
  justify-content: center;
`;

const Controls = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
`;

const PauePlayButtonIcon = styled.div`
  background-color: #ffff;
  border-radius: 30px;
  height: 55px;
`;

export { AddSongWithShareAndControls, Controls, PauePlayButtonIcon };
