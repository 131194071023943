import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { NotifyContext } from "../../contexts/NotfyContext";
import { PlayerContext } from "../../contexts/PlayerContext";
import MusicPlayer from "../MusicPlayer/MusicPlayer";
import MusicPlayerBar from "../MusicPlayerBar/MusicPlayerBar";
function PlayerView() {
  const { isActive } = useSelector((state) => state.player);
  const { isPlayerOpen } = useContext(PlayerContext);
  const { notify } = useContext(NotifyContext);

  return (
    <>
      {isActive && <MusicPlayerBar />}
      {isActive && (
        <MusicPlayer notify={notify} isOpened={isPlayerOpen} onBar={false} />
      )}
    </>
  );
}

export default PlayerView;
