import styled from "styled-components";

const PlayerTitleWithPowerPlayContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme === 'dark' && '#ffff'};
  align-items: center;
`;

const PlayerTrackTitle = styled.p`
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 90%;
  height: 28px;
`;
const PlayerSingerName = styled.p`
  font-size: 15px;
  color: rgba(117, 128, 139, 0.5);
  font-weight: 500;
  width: 80%;
  height: 19px;
`;
const PlayerDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  width: 80%;
`;
const PowerPlay = styled.div`
  display: flex;
  gap: 1em;
  height: 100%;

  #suffle_div {
    padding-top: 3px;
  }
`;
export {
  PlayerTitleWithPowerPlayContainer,
  PlayerTrackTitle,
  PlayerSingerName,
  PlayerDetails,
  PowerPlay,
};
