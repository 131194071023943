import React, { useContext, useEffect, useState } from "react";
import {
  BsArrowRepeat,
  BsFillPauseCircleFill,
  BsFillPlayCircleFill,
} from "react-icons/bs";
import { LuRepeat1 } from "react-icons/lu";
import {
  TbPlayerSkipBackFilled,
  TbPlayerSkipForwardFilled,
} from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { TrackContext } from "../../../contexts/TrackContext";
import {
  nextSong,
  playPause,
  prevSong,
} from "../../../redux/features/playerSlice";
import {
  AddSongWithShareAndControls,
  Controls,
  PauePlayButtonIcon,
} from "./PlayerControls.styled";

function PlayerControls({ restartSong, setIsLooped, isLooped }) {
  const dispatch = useDispatch();
  const {
    activeSong,
    tempCachedIndexesIndex,
    currentSongs,
    currentIndex,
    isActive,
    isPlaying,
    currentAlbumShuffleStatus,
    themeMode,
  } = useSelector((state) => state.player);
  console.log("activeSong ", activeSong);
  // const themeMode = 'light'
  const {
    duration,
    setTrackDuration,
    appTime,
    seekTime,
    setTrackAppTime,
    setTrackSeekTime,
  } = useContext(TrackContext);
  const [loopButton, setLoopButton] = useState(1);

  // useEffect(() => {
  // if(loopButton === 0) {
  //   handleBackwardSongPlay();
  // }
  // }, [appTime === duration]);

  function onPlayPauseControl() {
    if (isPlaying) {
      dispatch(playPause(false));
    } else {
      dispatch(playPause(true));
    }
  }

  function handleForwardSongPlay() {
    if (currentAlbumShuffleStatus) {
      dispatch(nextSong(tempCachedIndexesIndex + 1));
      dispatch(playPause(true));
      return;
    }
    dispatch(nextSong(currentIndex + 1));
    dispatch(playPause(true));

    console.log("activeSong", activeSong);
  }

  function handleBackwardSongPlay() {
    if (appTime > 2) {
      restartSong();
      return;
    }
    if (currentAlbumShuffleStatus) {
      dispatch(prevSong(tempCachedIndexesIndex - 1));
      dispatch(playPause(true));
      return;
    }
    if (currentIndex === 0) {
      return;
    }
    dispatch(nextSong(currentIndex - 1));
    dispatch(playPause(true));
  }

  return (
    <AddSongWithShareAndControls theme={themeMode}>
      <BsArrowRepeat
        color={
          !isLooped ? "rgb(205, 3, 4)" : themeMode === "dark" ? "#ffff" : "#000"
        }
        onClick={() => setIsLooped(false)}
        size={26}
      />
      <Controls>
        <TbPlayerSkipBackFilled
          size={30}
          onClick={() => handleBackwardSongPlay()}
        />
        <PauePlayButtonIcon onClick={() => onPlayPauseControl()}>
          {isPlaying ? (
            <BsFillPauseCircleFill size={55} style={{ color: "#CD0304" }} />
          ) : (
            <BsFillPlayCircleFill size={55} style={{ color: "#CD0304 " }} />
          )}
        </PauePlayButtonIcon>
        <TbPlayerSkipForwardFilled
          size={30}
          onClick={() => handleForwardSongPlay()}
        />
      </Controls>
      <LuRepeat1
        color={
          isLooped ? "rgb(205, 3, 4)" : themeMode === "dark" ? "#ffff" : "#000"
        }
        onClick={() => setIsLooped(true)}
        size={26}
      />
    </AddSongWithShareAndControls>
  );
}

export default PlayerControls;
