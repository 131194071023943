import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ModalProvider } from "./contexts/ModalContext";
import { NotifyProvider } from "./contexts/NotfyContext";
import { PlayerProvider } from "./contexts/PlayerContext";
import { TrackProvider } from "./contexts/TrackContext";
import "./index.css";
import { store } from "./redux/store";

// import MYtunes_loading from "../src/assets/MYtunes_loading.json"
const root = ReactDOM.createRoot(document.getElementById("root"));

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

if (urlParams.get("themeMode") === "light") {
  document.body.style.backgroundColor = "#FFFFFF";
} else {
  document.body.style.backgroundColor = "#000000";
}

root.render(
  <BrowserRouter>
    <NotifyProvider>
      <ModalProvider>
        <PlayerProvider>
          <TrackProvider>
            <Provider store={store}>
              <App />
            </Provider>
          </TrackProvider>
        </PlayerProvider>
      </ModalProvider>
    </NotifyProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals( ))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
