import styled from "styled-components";

const MusicPlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: ${({ isOpened }) => (isOpened ? "0" : "-100%")};
  width: 100%;
  transition: 500ms ease-in-out all;
  background: ${({ theme }) => theme === 'dark' ? '#000000' : '#FFFFFF'};
  height: 100vh;
  z-index: 1000;
`;

const MusicBannerWithControlsAndTrack = styled.div`
  display: flex;
  flex-direction: column;
  // margin-top: 2em;
  padding: 2em;
  gap: 0em;
  height: 100vh;

  padding-top: 1.5em;
`;

const MusicTrackWithControls = styled.div``;

const ControlsAndTrack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
const PlayerTopNav = styled.div`
  display: flex;
  // position: fixed;
  // top: 0px;
  // height: 60px;
  width: 100vw !important;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  background: ${({ theme }) => theme === 'dark' && '#000000'};
  z-index: 100;
  padding: 0.5em;

  padding: 1em 1em 0em 1em;
`;

export {
  ControlsAndTrack,
  MusicBannerWithControlsAndTrack,
  MusicPlayerContainer,
  MusicTrackWithControls,
  PlayerTopNav,
};
