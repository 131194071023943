import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { AllRoutes } from "./Router/routes";
import PlayerView from "./components/PlayerView/PlayerView";
import Toast from "./components/Toast/Toast";
import "./index.css";
import MusicLottieLoader from "./pages/Loader/MusicLottieLoader";
import {
  fetchAlbum,
  getLanguagesAndGenre,
  getLatestAlbum,
  setQueryParams,
} from "./redux/features/playerSlice";

function App() {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setQueryParams({
        mynId: params.get("mynId"),
        authToken: params.get("authToken"),
        themeMode: params.get("themeMode"),
        fromMobile: Boolean(params.get("fromMobile")),
      })
    );
    dispatch(fetchAlbum());
    dispatch(getLanguagesAndGenre());
    dispatch(getLatestAlbum());
  }, [dispatch, queryString]);

  return (
    <div
      className="App"
      style={{
        background: params.get("themeMode") === "light" && "#ffff",
      }}
    >
      <Toast />
      <Suspense fallback={<MusicLottieLoader />}>
        <AllRoutes />
      </Suspense>
      <PlayerView />
    </div>
  );
}

export default App;
